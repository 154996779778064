'use client';

import type { CSSProperties, ReactNode } from 'react';
import { useCallback, useEffect, useState } from 'react';
import type { Segment } from '@fxtr/i18n';
import type { CmsImage } from '$cms/types';
import vars from '$util/theme/vars';
import { googleAnalytics4 } from '$util/analytics/googleAnalytics4';
import { useGA4Tracking } from '$util/hooks/useGA4Tracking';
import { Card } from '@/components/atoms/Card';
import { HeadingLevels } from '@/components/atoms/HeadingLevels';
import type { CarouselProps } from '@/components/molecules/Carousel';
import { Carousel } from '@/components/molecules/Carousel';
import { Img } from '@/components/atoms/Img';
import { sendAnalyticsHowItWorksCarouselStep } from '@/util/internalAnalytics/client';
import { cloudinaryLoader, getCloudinarySrc } from '@/components/atoms/Img/cloudinary';

import styles from './index.module.scss';

interface CmsHowItWorksClientProps extends Omit<CarouselProps, 'children'> {
  step1Title: string;
  step1Description: string;
  step1Image: CmsImage;
  step1ImageMobile?: CmsImage;
  step1Color: string;
  step2Title: string;
  step2Description: string;
  step2Image: CmsImage;
  step2ImageMobile?: CmsImage;
  step2Color: string;
  step3Title: string;
  step3Description: string;
  step3Image: CmsImage;
  step3ImageMobile?: CmsImage;
  step3Color: string;
  step4Title: string;
  step4Description: string;
  step4Image: CmsImage;
  step4ImageMobile?: CmsImage;
  step4Color: string;
  confetti: CmsImage;
  segment: Segment;
  path: string;
}

export default function CmsHowItWorksClient({
  step1Title,
  step1Description,
  step1Image,
  step1ImageMobile,
  step1Color,
  step2Title,
  step2Description,
  step2Image,
  step2ImageMobile,
  step2Color,
  step3Title,
  step3Description,
  step3Image,
  step3ImageMobile,
  step3Color,
  step4Title,
  step4Description,
  step4Image,
  step4ImageMobile,
  step4Color,
  confetti,
  segment,
  path,
  ...props
}: CmsHowItWorksClientProps): ReactNode {
  const [currentStep, setCurrentStep] = useState(1);

  const imageLoader = cloudinaryLoader();

  const steps = [
    {
      title: step1Title,
      description: step1Description,
      image: step1Image,
      imageMobile: step1ImageMobile,
      color: step1Color,
    },
    {
      title: step2Title,
      description: step2Description,
      image: step2Image,
      imageMobile: step2ImageMobile,
      color: step2Color,
    },
    {
      title: step3Title,
      description: step3Description,
      image: step3Image,
      imageMobile: step3ImageMobile,
      color: step3Color,
    },
    {
      title: step4Title,
      description: step4Description,
      image: step4Image,
      imageMobile: step4ImageMobile,
      color: step4Color,
    },
  ].map((step) => {
    return {
      ...step,
      image: {
        ...step.image,
        url: imageLoader({
          // the regex replacement assumes that the image is a png or has no extension, if other formats are used, this will break
          src: getCloudinarySrc(step.image.url.replace(/(.+?)(\.png){0,1}$/i, '$1.webp')),
          width: 240,
        }),
        width: 240,
        height: 436,
      },
      imageMobile: step.imageMobile
        ? {
            ...step.imageMobile,
            url: imageLoader({
              // the regex replacement assumes that the image is a png or has no extension, if other formats are used, this will break
              src: getCloudinarySrc(step.imageMobile.url.replace(/(.+?)(\.png){0,1}$/i, '$1.webp')),
              width: 242,
            }),
            width: 242,
            height: 276,
          }
        : undefined,
    };
  });

  useEffect(() => {
    sendAnalyticsHowItWorksCarouselStep(segment, path, currentStep, 'ontouchstart' in document);
  }, [currentStep, path, segment]);

  const trackCarouselSteps = useCallback(() => {
    googleAnalytics4.howItWorksCarouselStepAccessed({
      page: path,
      step: currentStep,
      touchEnabled: 'ontouchstart' in document,
    });
  }, [currentStep, path]);

  useGA4Tracking(trackCarouselSteps);

  return (
    <Carousel
      className={styles.carousel}
      onItemChange={(currentIndex) => {
        setCurrentStep(currentIndex + 1);
      }}
      style={{ '--indicator-color': steps[currentStep - 1].color } as CSSProperties}
      {...props}
    >
      {steps.map(({ title, description, image, imageMobile, color }, i) => (
        <Card key={title} className={styles.carouselItem}>
          <div className={styles.carouselItemText}>
            <HeadingLevels semanticLevel={3} styleLevel={6} className={styles.carouselItemTitle}>
              <span className={styles.stepNumber}>{i + 1}</span> {title}
            </HeadingLevels>
            <p>{description}</p>
          </div>
          <div
            className={styles.carouselItemImage}
            style={
              {
                '--color': color,
              } as CSSProperties
            }
          >
            <picture className={styles.image}>
              <source srcSet={image.url} media={`(min-width: ${vars.mediaQueryBreakpoints.medium}px)`} />
              <source
                srcSet={imageMobile?.url}
                media={`(max-width: ${vars.mediaQueryBreakpoints.medium - 1}px)`}
              />
              <img src={image.url} loading="lazy" alt={image.alt} width={image.width} height={image.height} />
            </picture>
          </div>
          {i === 3 && (
            <Img
              src={confetti.url}
              alt={confetti.alt}
              loading="lazy"
              width={confetti.width}
              height={confetti.height}
              className={styles.confetti}
            />
          )}
        </Card>
      ))}
    </Carousel>
  );
}
